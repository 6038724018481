import { render, staticRenderFns } from "./index.vue?vue&type=template&id=27f93258&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=27f93258&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIconTickCircle: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconTickCircle.vue').default,CmsRichText: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/cms/CmsRichText.vue').default})
