
import { mapState } from "vuex";

import { PAGE_TYPE, QUERY_PAGES } from "./-index.query";
import { seoMixin, pageMixin } from "~/mixins";

export default {
  mixins: [seoMixin, pageMixin],

  async asyncData({ app, error, params: { handle: uid } }) {
    const { [PAGE_TYPE]: data } = await app.$getPrismicData({
      query: QUERY_PAGES,
      variables: { uid },
    });

    if (data?.slices == null ?? data?.slices?.length === 0) {
      error({ statusCode: 404, message: "Page not found" });
      return;
    }

    return {
      uid,
      slices: data.slices,
      title: data.title,
      pageType: PAGE_TYPE,
      description: data.description,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      metaRobots: data.metaRobots,
      metaFields: { _linkType: data._linkType, _meta: data._meta },
    };
  },

  computed: {
    ...mapState("pages", ["notificationContent"]),
  },
};
